// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   embed_code -> textarea /
//   ratio -> option / Seitenverhältnis
// end of automatic

import React from "react"
import BlockWrapper from "../technical/BlockWrapper"

import KlaroConsentInfo from "../modules/KlaroConsentInfo"
import useKlaroConsentable from "../../hooks/useKlaroConsentable"

const EmbedBlock = ({ block }) => {
  const { usageConsented, appInfo, handleKlaroConsent } = useKlaroConsentable(
    block.user_consent
  )

  return (
    <BlockWrapper block={block}>
      {typeof window !== "undefined" && window.storyblok?.inEditor && (
        <div
          className="py-2 mb-8 rounded px-grid"
          style={{ backgroundColor: "#09b3af" }}
        >
          <p className="font-medium prose text-white">
            <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
            <b>Embed-Block:</b> Eingebettete Inhalte, welche Scripte verwenden,
            können unter Umständen in der <i>Vorschau</i> nicht korrekt
            angezeigt werden.
          </p>
        </div>
      )}
      <div className={`${block.headline?.length > 0 ? "pt-8" : ""}`}>
        {block.user_consent && usageConsented === false && appInfo ? (
          <div className={`embed embed-${block.ratio}`}>
            <KlaroConsentInfo
              appInfo={appInfo}
              handleKlaroConsent={handleKlaroConsent}
              renderWrapper={false}
              textPositionClassName="text-left"
            />
          </div>
        ) : (
          <div
            className={`embed embed-${block.ratio ? block.ratio : "16by9"}`}
            dangerouslySetInnerHTML={{ __html: block.embed_code }}
          ></div>
        )}
      </div>
    </BlockWrapper>
  )
}

export default EmbedBlock
